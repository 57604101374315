<template>
    <div v-show="firmware_selected==false">Upload Firmware</div>
    <div class="flexbox">
        <div class="left_column">  
            <va-select label="Select Firmware" @update:modelValue="firmwareSelect" v-model="current_firmware_name" :options="firmware_names"  class="mr-2 mb-2" v-show="firmware_selected==false">
                Content
            </va-select>
            <div v-show="firmware_selected">
                <div class="va-table-responsive" v-show="firmware_selected">
                    <table class="va-table va-table--clickable va-table--striped va-table--hoverable my-table" >
                        <thead>
                            <tr>
                                <th>Online Versions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="version in versions" :key="version" @click="handleVersionTableClick">
                                <td>{{version}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div v-show="version_selected">
                <va-input class="mb-4 mr-4 my-input" color="#454545" label="Selected Version" v-model="selected_version" stateful />
                <va-button class="mb-12 mr-12 mybutton" color="#454545" text-color="#FFFFFF" @click="deleteFirmware"> Delete Firmware </va-button>
            </div>
        </div>
        
        <div class="right_column" v-show="firmware_selected">  
            <va-input class="mb-4 mr-4 my-input" color="#454545" label="New Version" v-model="new_version" stateful />
            <input type="file" @change="handleFileUpload" single>
            <va-button class="mb-12 mr-12 mybutton" color="#454545" text-color="#FFFFFF" @click="submitFile"> Upload Firmware </va-button>

        </div>
    </div>
        <div>
            <va-button class="mb-12 mr-12 mybutton" color="#454545" text-color="#FFFFFF" @click="back" v-show="firmware_selected"> Back </va-button>
        </div>
</template>


<script>



import {UploadVersion, GetFirmwareNames, GetVersions, DeleteVersion} from "../js/cloudProcedures"
import validator from 'validator';

export default {
    name: 'Upload',
    data () {
      return {
      new_version: "",
      file: "",
      bin_data: "",
      uploadSelectedFiles: [],
      firmware_selected: false,
      firmware_names: [],
      blob_names: [],
      selected_blob : "",
      current_firmware_name: "",
      version_selected: false,
      selected_version:"",
      versions: [],
      }
    },
    beforeMount() {
    },
    async created() {
    console.log("Fetching Firmware Names");
    await GetFirmwareNames(result => {
        console.log(result);
        this.firmware_names = result;
    });

    },
    methods:{
        deleteFirmware(){
            DeleteVersion(this.current_firmware_name, this.selected_version, result=>
            {
                //console.log(result);
                this.$vaToast.init(result);
                if(result=="Firmware Deleted")
                {
                    GetVersions(this.current_firmware_name, result=>{
//                        console.log(result);
                        this.versions = result.versions;
                    })
                }
            })
        },
        firmwareSelect(item) {
            this.current_firmware_name = item;
            this.firmware_selected=true;
            GetVersions(this.current_firmware_name, result=>{
             //   console.log(result);
                this.versions = result.versions;
            })
            //this.FetchFiles();
        },
        back(){
            this.firmware_selected=false;
            this.current_firmware_name = "";
            this.blob_names=[];
        },
        handleVersionTableClick (item) {
            this.selected_version = item.srcElement.innerText;
           // console.log("Selected version", this.selected_version);
            this.version_selected = true;
        },
        handleFileUpload( event ){
            this.file = event.target.files[0];
           // console.log("got one file:" + this.file);
        },
        async createBase64File(fileObject, callback){
            const reader = new FileReader();
            reader.onload = (e)=>{
                this.bin_data = e.target.result;
             //   console.log(this.file)
                callback();
                return;
            };
            reader.readAsDataURL(fileObject);
        },
        async submitFile(){
            await this.createBase64File(this.file, ()=>{
            if(this.bin_data == ""){
        //        console.log("Could not load binary file from computer");
                this.$vaToast.init('Could not load binary file from computer');
                return;
            }
            if (!validator.isSemVer(this.new_version)){
          //      console.log("Error, new version does not follow SemVer Standard");
                this.$vaToast.init('Error, new version does not follow SemVer Standard');
                return;
            }
            if (this.versions.includes(this.new_version)){
           //     console.log("Error, Version already exists on server");
                this.$vaToast.init('Error, Version already exists on server');
                return;

            }
             console.log(UploadVersion(this.current_firmware_name,this.new_version, this.bin_data, result=>{
           //     console.log(result);
                this.$vaToast.init(result);
                if(result=="Firmware Uploaded"){
                    GetVersions(this.current_firmware_name, result=>{
                    //    console.log(result);
                        this.versions = result.versions;
                    })
                }
            }));
            });

            
        }

    }
}
</script>

<style scoped>

    tr:active {
        background-color: red !important;
    }
    .mybutton{
        margin-top: 20px;
        justify-content: center;
        align-items: center;
        max-width: 300px;
    }
    .flexbox {
        display:flex;
        max-width: 1200px;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .left_column{
        display:flex;
        flex-direction: column;
        flex-basis: 100%;
        min-width: 350px;
        margin-right: 50px;
        flex: 1;
    }
    .right_column{
        display:flex;
        flex-direction: column;
        flex-basis: 100%;
        min-width: 350px;
        flex: 1;
    }
    .va-input {
        color:white !important;
        background-color: black !important;
    }

    tr:active {
        background-color: red !important;
    }
    
    .my-table{
        width:100%;
        margin-bottom: 20px;
    }
    
    .my-input
    {
        padding-top:20px;
        max-width: 300px;
        font-size: 0.8rem !important;
    }
  
    .va-table--hoverable tr:hover td {
    background-color: #eff4f5 !important;
    }

    .va-table--clickable tr:active{
        background-color: #0ae653 !important; 
    }
</style>
